<template>
  <v-container fluid
               class="px-12"
  >
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('capturesPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="searchTags"
          :items="tags"
          item-text="name"
          item-value="id"
          color="secondary"
          chips
          :label="$t('capturesPage.filterTags')"
          multiple
        >
          <template #selection="data">
            <v-chip
              :key="JSON.stringify(data.item.id)"
              class="secondary"
              color="white"
              close
              @click:close="removeTag(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="isAdmin">
        <v-autocomplete
          v-model="searchUsers"
          :items="users"
          item-text="username"
          item-value="id"
          color="secondary"
          chips
          :label="$t('capturesPage.filterUsers')"
          multiple
        >
          <template #selection="data">
            <v-chip
              :key="JSON.stringify(data.item.id)"
              class="secondary"
              color="white"
              close
              @click:close="removeUser(data.item.id)"
            >
              {{ data.item.username }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="isCloudEnv">
        <v-autocomplete
          v-model="searchMachines"
          :items="machines"
          item-text="machine_name"
          item-value="id"
          color="secondary"
          chips
          :label="$t('capturesPage.filterMachines')"
          multiple
        >
          <template #selection="data">
            <v-chip
              :key="JSON.stringify(data.item.id)"
              class="secondary"
              color="white"
              close
              @click:close="removeMachine(data.item.id)"
            >
              {{ data.item.machine_name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="isCloudEnv">
        <v-autocomplete
          v-model="searchLocations"
          :items="locations"
          item-text="name"
          item-value="id"
          color="secondary"
          chips
          :label="$t('capturesPage.filterLocations')"
          multiple
        >
          <template #selection="data">
            <v-chip
              :key="JSON.stringify(data.item.id)"
              class="secondary"
              color="white"
              close
              @click:close="removeLocation(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-menu
          v-model="startDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="startDateDisp"
              :label="$t('capturesPage.filterStartDate')"
              readonly
              clearable
              persistent-hint
              append-icon="event"
              class="mt-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            :max="endDate !== '' ? endDate : currentDate"
            @input="startDatePicker = false"
          />
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="endDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          :nudge-right="40"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="endDateDisp"
              :label="$t('capturesPage.filterEndDate')"
              readonly
              clearable
              persistent-hint
              append-icon="event"
              class="mt-3"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            :min="startDate !== '' ? startDate : undefined"
            :max="currentDate"
            @input="endDatePicker = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(capture, index) in captures"
             :key="index"
             cols="3"
      >
        <v-card max-width="375"
                :ripple="false"
                @click="gotoCapture(capture.id)"
        >
          <v-menu
            close-on-click
            offset-y
            offset-x
          >
            <template #activator="{on}">
              <v-btn
                color="white"
                class="my-2"
                :class="$style.btn"
                tile
                icon
                small
                absolute
                right
                :ripple="false"
                v-on="on"
              >
                <v-icon large>
                  more_vert
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="gotoAccount(capture)"
              >
                <v-list-item-title>
                  {{ $t('capturesPage.item.viewAccount') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="exportCapture(capture)"
              >
                <v-list-item-title>{{ $t('capturePage.export') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isAdminUser()"
                @click="onShowDelete(capture)"
              >
                <v-list-item-title>{{ $t('capturePage.delete') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isDermaidEnabled() && capture.compression_type !== 2"
                @click="addToDermaidStudio(capture)"
              >
                <v-list-item-title>
                  Add to oVio<span class="ai-studio-text">Ai</span>
                  Studio
                </v-list-item-title>
              </v-list-item>
              <v-list-group
                v-if="capture.compression_type !== 2"
                no-action
                @click.stop
              >
                <template #activator>
                  <v-list-item-title>
                    {{ $t('capturesPage.item.addPresets') }}
                  </v-list-item-title>
                </template>

                <v-list-item
                  v-for="profile in presetProfiles"
                  :key="profile.id"
                  class="pl-6"
                  @click="addPresets(capture, profile)"
                >
                  <v-list-item-title v-text="profile.name" />
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-if="capture.compression_type !== 2"
                @click="addVideo(capture)"
              >
                <v-list-item-title>
                  {{ $t('capturesPage.item.addVideo') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-img v-if="capture.thumbnail_url !== undefined ||
                   capture.thumbnail_url !== ''"
                 class="white--text align-end"
                 :class="$style.roundedImg"
                 :src="getCaptureImage(index)"
                 aspect-ratio="1.5"
          >
            <v-card-title>
              {{ capture.account_name }}
            </v-card-title>
          </v-img>
          <v-icon v-else>
            account_box
          </v-icon>
          <v-card-text class="text--primary">
            <v-container class="py-0">
              <v-row no-gutters>
                <v-col cols="11">
                  <div>{{ getCaptureCreateTime(index) }}</div>
                  <div v-if="capture.uploaded_files >= capture.total_files">
                    {{ capture.customer_location }}
                  </div>
                  <div v-if="capture.uploaded_files >= capture.total_files">
                    {{ capture.machine_name }}
                  </div>
                  <div v-if="capture.uploaded_files < capture.total_files"
                       class="secondary--text"
                  >
                    {{ $t('capturesPage.uploading',
                          [capture.uploaded_files, capture.total_files]) }}
                  </div>
                  <div v-if="capture.uploaded_files < capture.total_files">
                    <!-- for matching spacing -->
                  </div>
                </v-col>
                <v-col v-if="capture.audio_file"
                       cols="1"
                       class="d-flex justify-end py-0"
                >
                  <v-icon color="secondary">
                    audiotrack
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <span />
        </v-card>
      </v-col>
      <v-snackbar
        v-model="localExportSnackbar"
        color="secondary"
      >
        <div class="font-weight-bold">
          {{ $t('capturePage.localExportCapture') }}
        </div>
      </v-snackbar>
      <v-snackbar
        v-model="cloudExportSnackbar"
        color="secondary"
      >
        <div class="font-weight-bold">
          {{ $t('capturePage.cloudExportCapture') }}
        </div>
      </v-snackbar>
      <v-snackbar
        v-model="exportProgressErrorSnackbar"
        color="secondary"
      >
        <div class="font-weight-bold">
          {{ $t('capturePage.exportInProgress') }}
        </div>
      </v-snackbar>
      <v-snackbar
        v-model="existsInImageStudioErrorSnackbar"
        color="secondary"
      >
        <div class="font-weight-bold">
          {{ $t('capturePage.existsInImageStudio') }}
        </div>
      </v-snackbar>
      <AccountCaptureDelete v-row
                            :dialog="showDelete"
                            :capture="captureSelected"
                            @close="onCloseDialog()"
                            @ok="onCaptureDeleted()"
      />
      <v-pagination
        v-model="currentPage"
        class="my-4"
        :length="captureCount"
        :total-visible="7"
        @="changePage"
      />
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver/FileSaver';
// import { mapGetters } from 'vuex';
import capturesService from '../js/services/capturesService';
import tagsService from '../js/services/tagsService';
import machinesService from '../js/services/machinesService';
import videoStudioService from '../js/services/videoStudioService';
import imageStudioService from '../js/services/imageStudioService';
import userService from '../js/services/userService';
import config from '../js/config';
import {
  CAPTURE_PAGE, ACCOUNT_PAGE, DERMAID_STUDIO_PAGE,
} from '../js/router/pages';
import applicationService from '../js/services/applicationService';
import captureExportsService from '../js/services/captureExportsService';
import dermaidService from '../js/services/dermaidService';
import presetFrameProfileService from '../js/services/presetFrameProfilesService';

export default {
  name:       'Captures',
  components: {
    AccountCaptureDelete: () => import('../components/Account/AccountCaptureDelete.vue'),
  },
  data() {
    return {
      captures:                         [],
      currentPage:                      1,
      pageCount:                        20,
      columnWidth:                      4,
      captureCount:                     0,
      tags:                             [],
      users:                            [],
      machines:                         [],
      locations:                        [],
      searchTags:                       [],
      searchMachines:                   [],
      searchLocations:                  [],
      searchUsers:                      [],
      startDatePicker:                  false,
      endDatePicker:                    false,
      endDate:                          '',
      startDate:                        '',
      currentDate:                      new Date().toISOString(),
      isCloudEnv:                       applicationService.isInCloudMode(),
      isAdmin:                          userService.isAdmin(),
      presetProfiles:                   [],
      showDelete:                       false,
      captureSelected:                  null,
      localExportSnackbar:              false,
      cloudExportSnackbar:              false,
      exportProgressErrorSnackbar:      false,
      existsInImageStudioErrorSnackbar: false,
      exportTimer:                      null,
      captureExportId:                  0,
    };
  },
  computed: {
    endDateDisp: {
      get() {
        if (this.endDate === '') {
          return '';
        }

        return this.$moment(this.endDate).format(config.dateFormat);
      },
      set() {
        this.endDate = '';
      },
    },
    startDateDisp: {
      get() {
        if (this.startDate === '') {
          return '';
        }

        return this.$moment(this.startDate).format(config.dateFormat);
      },
      set() {
        this.startDate = '';
      },
    },

    /**
     * Returns logged user.
     *
     * @return {null|Object}
     */
    loggedUser() {
      return userService.getAuthUser();
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.loadCaptures();
        this.$vuetify.goTo(0, 0, 200);
      },
      deep: true,
    },
    searchTags() {
      // console.log('loading from search tags');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
    searchMachines() {
      // console.log('loading from search tags');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
    searchLocations() {
      // console.log('loading from search tags');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
    searchUsers() {
      // console.log('loading from search tags');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
    startDate() {
      // console.log('loading from start date');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
    endDate() {
      // console.log('loading from end date');
      this.captures = [];
      this.loadCaptures();
      this.loadCaptureCount();
    },
  },
  mounted() {
    this.initialize();
  },
  activated() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.tag) {
        this.searchTags.push(this.$route.params.tag);
      }
      this.loadCaptures();
      this.loadCaptureCount();
      this.loadSearchItems();
      this.loadPresetFrameProfiles();
    },
    isDermaidEnabled() {
      return userService.getDermaidEnabled();
    },
    async addToDermaidStudio(capture) {
      try {
        const projectData = {
          name:      `${capture.account_name} - ${capture.id}`,
          captureId: capture.id,
        };
        const { id: projectId } = await dermaidService.createDermaidProject(projectData);

        this.$router.push({ name: DERMAID_STUDIO_PAGE, query: { projectId } });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error adding to Dermaid studio:', error);
      }
    },

    /**
     * Returns true if the user is admin
     *
     * @return {boolean}
     */
    isAdminUser() {
      return userService.isAdmin();
    },
    async loadCaptures() {
      const options = {
        currentPage:     this.currentPage,
        capturesPerPage: this.pageCount,
      };

      const filters = {
        tags:      this.searchTags.join(','),
        machines:  this.searchMachines.join(','),
        locations: this.searchLocations.join(','),
        users:     this.searchUsers.join(','),
        startDate: this.startDate,
        endDate:   this.endDate,
      };

      this.captures = await capturesService.getAll(options, filters);
      this.captures.forEach(async capture => {
        capture.loading = 'secondary';
        if (capture.is_uploading) {
          capture.upload_status = await capturesService.getUploadStatus(capture.id);
        }
      });
    },
    async loadCaptureCount() {
      const filters = {
        tags:      this.searchTags.join(','),
        machines:  this.searchMachines.join(','),
        locations: this.searchLocations.join(','),
        users:     this.searchUsers.join(','),
        startDate: this.startDate,
        endDate:   this.endDate,
      };

      const totalCount = await capturesService.getCaptureCount(filters);

      this.captureCount = Math.ceil(totalCount / this.pageCount);
    },
    async loadSearchItems() {
      const rootAccessLevel = userService.permissions.oVio.accessLevel;

      this.tags = await tagsService.loadTags();
      this.machines = await machinesService.getMachines();
      this.locations = await machinesService.getCustomerLocations();
      this.users = await userService.getUserList();

      if (this.loggedUser.access_level < rootAccessLevel) {
        this.users = this.users.filter(user => user.access_level < rootAccessLevel);
      }
    },
    getCaptureImage(index) {
      // console.log(`capture id ${capture.id}`);
      const capture = this.captures[index];

      if (capture && capture.thumbnail_url) {
        return `${config.apiUrl}${capture.thumbnail_url}`;
      }

      return '';
    },
    getCaptureCreateTime(index) {
      const capture = this.captures[index];

      return this.$moment(capture.date_created).format(config.dateTimeFormat);
    },
    gotoCapture(id) {
      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: id,
        },
      });
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.loadCaptures();
    },
    removeTag(id) {
      this.searchTags = this.searchTags.filter(t => t !== id);
    },
    removeUser(id) {
      this.searchUsers = this.searchUsers.filter(u => u !== id);
    },
    removeMachine(id) {
      this.searchMachines = this.searchMachines.filter(m => m !== id);
    },
    removeLocation(id) {
      this.searchLocations = this.searchLocations.filter(m => m !== id);
    },
    gotoAccount(capture) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: capture.account_id,
        },
      });
    },
    /**
    * Download all media for capture
    */
    async exportMedia() {
      try {
        if (this.captureExportId > 0) {
          this.exportProgressErrorSnackbar = true;

          return;
        }

        this.captureExportId = await captureExportsService.createCaptureExport(this.capture.id);

        if (this.isCloudEnv) {
          this.cloudExportSnackbar = true;
          this.captureExportId = 0;
        } else {
          this.exportTimer = setInterval(this.getExportProgress, 5000);

          this.localExportSnackbar = true;
        }
      } catch (error) {
        this.captureExportId = 0;
      }
    },

    async getExportProgress() {
      try {
        const isComplete = await captureExportsService
          .isCaptureExportComplete(this.captureExportId);

        if (isComplete) {
          clearInterval(this.exportTimer);
          this.exportTimer = null;
          await this.downloadExportFile();
          this.captureExportId = 0;
        }
      } catch (error) {
        clearInterval(this.exportTimer);
        this.exportTimer = null;
        this.captureExportId = 0;
      }
    },

    async downloadExportFile() {
      try {
        const zipFile = await captureExportsService
          .download(this.captureExportId);
        const filename = `${this.$moment().format('MM-DD-YYYY')}_${this.capture.id}_${this.captureExportId}_mesh.zip`;

        saveAs(new Blob([zipFile], { type: 'application/zip' }), filename);
      } catch (error) {
        // console.log(`downloadExportFile Error ${error}`);
      }
    },
    async exportCapture(capture) {
      this.capture = capture;
      this.exportMedia();
    },

    async addVideo(capture) {
      const videoSource = await capturesService.getVideoSrc(capture.id);

      videoStudioService.addVideo({
        video:        videoSource,
        account_name: capture.account_name,
        scan_mode:    capture.scan_mode,
        date_created: capture.date_created,
        captureId:    capture.id,
        accountId:    capture.account_id,
      });
    },
    async addPresets(capture, presetProfile) {
      const images = await capturesService.getImageList(capture.id);
      const selectedImages = [];
      const presets = presetProfile.preset_frames;

      images
        .filter(el => presets.includes(el.frame + 1))
        .forEach(image => {
          selectedImages.push({
            ...image,
            captureId:   capture.id,
            accountId:   capture.account_id,
            accountName: capture.account_name,
          });
        });

      this.existsInImageStudioErrorSnackbar = !imageStudioService.addToImageStudio(selectedImages);
    },
    onShowDelete(capture) {
      this.captureSelected = capture;
      this.showDelete = true;
    },
    onCloseDialog() {
      this.showDelete = false;
    },
    onCaptureDeleted() {
      this.showDelete = false;
      this.loadCaptures();
    },

    async loadPresetFrameProfiles() {
      try {
        const profiles = await presetFrameProfileService.getPresetFrameProfiles();

        this.presetProfiles = profiles.filter(profile => profile.id !== 9999);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading preset frame profiles:', error);
      }
    },
  },
};
</script>

<style lang="scss" module>
.btn {
  z-index: 1;
}
.roundedImg {
  border-radius: 4px 4px 0 0;
}
</style>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}

.ai-studio-text {
  color: #F26924;
  font-style: italic;
  margin-right: 4px;
}
</style>
