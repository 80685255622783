<template>
  <v-container
    class="pa-0"
  >
    <v-navigation-drawer
      app
      clipped
      right
      width="300"
    >
      <h2 class="pl-2">
        {{ $t('videoStudio.videoComparePlaylists.sideBar.title') }}
      </h2>
      <v-list>
        <v-list-item-content class="pl-4">
          <v-list-item-title>
            {{ $t('videoStudio.videoComparePlaylists.sideBar.player1') }}
          </v-list-item-title>
        </v-list-item-content>
        <draggable
          :list="playlist1"
          group="videos"
          style="cursor: pointer;"
          @end="dragEnded"
        >
          <v-list-item
            v-for="item in playlist1"
            :key="item.captureId"
          >
            <v-list-item-avatar min-height="50px">
              <v-img
                class="white--text align-end"
                :src="item.preview"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.account_name + ' - ' + item.captureId }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getCaptureCreateTime(item.date_created) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu top
                      close-on-click
                      offset-y
                      offset-x
              >
                <template #activator="{on}">
                  <v-btn icon
                         v-on="on"
                  >
                    <v-icon color="grey lighten-1">
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <v-list color="grey lighten-3">
                  <v-list-item>
                    <v-list-item-title @click="movePlaylist1ToPlaylist2(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.movePlayer2') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="removeFromPlaylist1(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.removePlaying') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="removeFromStudio(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.removeStudio') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list>
      <v-list>
        <v-list-item-content class="pl-4">
          <v-list-item-title>
            {{ $t('videoStudio.videoComparePlaylists.sideBar.player2') }}
          </v-list-item-title>
        </v-list-item-content>
        <draggable
          :list="playlist2"
          group="videos"
          style="cursor: pointer;"
          @end="dragEnded"
        >
          <v-list-item
            v-for="item in playlist2"
            :key="item.captureId"
          >
            <v-list-item-avatar min-height="50px">
              <v-img
                class="white--text align-end"
                :src="item.preview"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.account_name + ' - ' + item.captureId }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getCaptureCreateTime(item.date_created) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu top
                      close-on-click
                      offset-y
                      offset-x
              >
                <template #activator="{on}">
                  <v-btn icon
                         v-on="on"
                  >
                    <v-icon color="grey lighten-1">
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <v-list color="grey lighten-3">
                  <v-list-item>
                    <v-list-item-title @click="movePlaylist2ToPlaylist1(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.movePlayer1') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="removeFromPlaylist2(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.removePlaying') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="removeFromStudio(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.removeStudio') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list>
      <v-list>
        <v-list-item-content class="pl-4">
          <v-list-item-title>
            {{ $t('videoStudio.videoComparePlaylists.sideBar.inactiveVideos') }}
          </v-list-item-title>
        </v-list-item-content>
        <draggable
          :list="unselectedVideos"
          group="videos"
          style="cursor: pointer;"
          @end="dragEnded"
        >
          <v-list-item
            v-for="item in unselectedVideos"
            :key="item.captureId"
          >
            <v-list-item-avatar min-height="50px">
              <v-img
                class="white--text align-end"
                :src="item.preview"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.account_name + ' - ' + item.captureId }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu top
                      close-on-click
                      offset-y
                      offset-x
              >
                <template #activator="{on}">
                  <v-btn icon
                         v-on="on"
                  >
                    <v-icon color="grey lighten-1">
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <v-list color="grey lighten-3">
                  <v-list-item>
                    <v-list-item-title @click="addToPlaylist1(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.addPlayer1') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="addToPlaylist2(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.addPlayer2') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="removeFromStudio(item.captureId)">
                      {{ $t('videoStudio.videoComparePlaylists.sideBar.removeStudio') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list>
    </v-navigation-drawer>
    <VideoPlaylistPlayer
      :playlists="playlists"
      :show-date="showDate"
      :show-timestamp="showTimestamp"
    />
  </v-container>
</template>

<script>
// import _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';
import draggable from 'vuedraggable';
import config from '../../js/config';
import videoStudioService from '../../js/services/videoStudioService';
import VideoPlaylistPlayer from './VideoPlaylistPlayer.vue';
import applicationService from '../../js/services/applicationService';

export default {
  name:       'VideoComparePlaylist',
  components: {
    draggable,
    VideoPlaylistPlayer,
  },
  props: {
    videos: {
      type:    Array,
      default: () => [],
    },
    showDate: {
      type:    Boolean,
      default: false,
    },
    showTimestamp: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      playlist1:        [],
      playlist2:        [],
      unselectedVideos: [],
      playlists:        [],
      localVideos:      [],
    };
  },
  watch: {
    videos(newVal) {
      // console.log('videos watch');
      if (newVal.length === 0) {
        return;
      }
      this.localVideos = newVal;

      this.loadVideosFromStorage();
      if (this.playlist1.length === 0 && this.playlist2.length === 0) {
        // Means nothings is stored in local configuration
        const videosMidPoint = Math.floor(this.localVideos.length / 2);

        this.playlist1 = this.localVideos.slice(0, videosMidPoint);
        this.playlist2 = this.localVideos.slice(videosMidPoint, videosMidPoint * 2);
        this.unselectedVideos = this.localVideos.slice(videosMidPoint * 2, this.localVideos.length);
        this.playlists = [ this.playlist1, this.playlist2 ];

        videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
      }
    },
  },
  mounted() {
    // console.log('playlist mounted');

    this.localVideos = this.videos;

    this.loadVideosFromStorage();
    if (this.playlist1.length === 0 && this.playlist2.length === 0 && this.localVideos.length > 0) {
      // Means nothings is stored in local configuration
      const videosMidPoint = Math.floor(this.localVideos.length / 2);

      this.playlist1 = this.localVideos.slice(0, videosMidPoint);
      this.playlist2 = this.localVideos.slice(videosMidPoint, videosMidPoint * 2);
      this.unselectedVideos = this.localVideos.slice(videosMidPoint * 2, this.localVideos.length);
      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    }
  },
  methods: {
    loadVideosFromStorage() {
      if (this.localVideos.length === 0) {
        return;
      }

      const playlists = videoStudioService.getPlaylistsSelectedVideos();

      if (!playlists) {
        return;
      }

      // search the cached playlists to make sure they still exist in the video studio
      this.playlist1 = [];
      playlists.playlist1.forEach(video => {
        const vid = this.localVideos.find(vid => video.captureId === vid.captureId);

        if (vid) {
          this.playlist1.push(vid);
        }
      });

      this.playlist2 = [];
      playlists.playlist2.forEach(video => {
        const vid = this.localVideos.find(vid => video.captureId === vid.captureId);

        if (vid) {
          this.playlist2.push(vid);
        }
      });

      // populate the rest into unselected videos
      this.localVideos.forEach(video => {
        const video1 = this.playlist1.find(vid => video.captureId === vid.captureId);
        const video2 = this.playlist2.find(vid => video.captureId === vid.captureId);

        if (!video1 && !video2) {
          this.unselectedVideos.push(video);
        }
      });

      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    getCaptureCreateTime(dateCreated) {
      return this.$moment(dateCreated).format(config.dateTimeFormat);
    },

    dragEnded() {
      this.playlists = [ this.playlist1, this.playlist2 ];
      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);

      this.$emit('playing-updated', this.playlists.flat());
    },

    addToPlaylist1(captureId) {
      const selVideo = this.unselectedVideos.find(vid => vid.captureId === captureId);

      this.playlist1.push(selVideo);
      this.unselectedVideos = this.unselectedVideos.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    addToPlaylist2(captureId) {
      const selVideo = this.unselectedVideos.find(vid => vid.captureId === captureId);

      this.playlist2.push(selVideo);
      this.unselectedVideos = this.unselectedVideos.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];
      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    removeFromStudio(captureId) {
      // console.log(`removeFromStudio ${captureId}`);

      this.localVideos = this.localVideos.filter(vid => vid.captureId !== captureId);
      this.playlist1 = this.playlist1.filter(vid => vid.captureId !== captureId);
      this.playlist2 = this.playlist2.filter(vid => vid.captureId !== captureId);
      this.unselectedVideos = this.unselectedVideos.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
      videoStudioService.removeVideo(captureId);

      this.$emit('videos-update', this.localVideos);
    },

    movePlaylist1ToPlaylist2(captureId) {
      const selVideo = this.playlist1.find(vid => vid.captureId === captureId);

      this.playlist2.push(selVideo);
      this.playlist1 = this.playlist1.filter(vid => vid.captureId !== captureId);

      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    movePlaylist2ToPlaylist1(captureId) {
      const selVideo = this.playlist2.find(vid => vid.captureId === captureId);

      this.playlist1.push(selVideo);
      this.playlist2 = this.playlist2.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];

      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    removeFromPlaylist1(captureId) {
      const selVideo = this.playlist1.find(vid => vid.captureId === captureId);

      this.unselectedVideos.push(selVideo);
      this.playlist1 = this.playlist1.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];
      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    removeFromPlaylist2(captureId) {
      const selVideo = this.playlist2.find(vid => vid.captureId === captureId);

      this.unselectedVideos.push(selVideo);
      this.playlist2 = this.playlist2.filter(vid => vid.captureId !== captureId);
      this.playlists = [ this.playlist1, this.playlist2 ];
      videoStudioService.setPlaylistsVideos(this.playlist1, this.playlist2);
    },

    async save(filename) {
      this.fileName = filename;

      const exportId = await videoStudioService
        .comparePlaylistVideos(this.playlist1, this
          .playlist2, this.showDate, this.showTimestamp);

      if (!applicationService.isInCloudMode()) {
        setTimeout(async () => {
          await this.checkStatus(exportId);
        }, 1000);
      }
    },

    async checkStatus(exportId) {
      const status = await videoStudioService.getVideoStatus(exportId);

      if (status === 0)       {
        // Still downloading
        setTimeout(async () => {
          await this.checkStatus(exportId);
        }, 1000);
      }

      // finished
      if (status === 1) {
        const file = await videoStudioService.downloadVideo(exportId);

        let { fileName } = this;

        if (!fileName) {
          fileName = `export_video_${exportId}.mp4`;
        }
        saveAs(new Blob([file],
          fileName, { type: 'video/mp4' }));

        this.$emit('save-completed');
      }

      if (status === 2) {
        // Errored
        this.$emit('save-error');
      }
    },

  },
};
</script>
