<template>
  <v-container class="no-gutter pt-0 mt-0">
    <v-row class="no-gutter">
      <v-col>
        <v-card outlined
                :loading="is3DViewer && modelLoading ? 'secondary' : false"
        >
          <v-toolbar
            :class="$style.title"
            dense
            elevation="0"
            class="justify-center primary white--text"
          >
            <v-toolbar-title>{{ getTitle() }}</v-toolbar-title>
            <v-spacer />
            <v-btn :class="$style.navigation"
                   color="white"
                   class="mx-2"
                   icon
                   @click="infoDialog = !infoDialog"
            >
              <v-icon>info</v-icon>
            </v-btn>
            <v-menu offset-y>
              <template #activator="{ on }">
                <v-btn
                  :class="$style.navigation"
                  color="white"
                  class="mx-2"
                  icon
                  v-on="on"
                >
                  <v-icon>more_horiz</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="exportCapture()"
                >
                  <v-list-item-title>{{ $t('capturePage.export') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="capture.compression_type!==2 && !capture.has_avatar"
                  @click="createAvatar()"
                >
                  <v-list-item-title>{{ $t('capturePage.createAvatar') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isAdminUser()"
                  @click="showReassign=true"
                >
                  <v-list-item-title>{{ $t('capturePage.reassignCapture') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isAdminUser()"
                  @click="showDelete=true"
                >
                  <v-list-item-title>{{ $t('capturePage.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <CaptureDetailsView
                  :show3dviewer="capture.compression_type===2"
                  :showaudio="capture.audio_file!==undefined && capture.audio_file!==''"
                  :showavatar="capture.has_avatar"
                  @view-changed="changeView"
                />
              </v-col>
            </v-row>
            <v-row v-if="!is3DViewer && !isAudio && !isVideo"
                   justify="center"
            >
              <v-col>
                <CaptureDetailsItems
                  v-if="images.length"
                  :items="images"
                  :selected-items="selectedImages"
                  :view="view"
                  @select="toggleImage"
                />
              </v-col>
            </v-row>
            <v-row v-else-if="isVideo"
                   justify="center"
            >
              <v-col />
              <v-col>
                <div>
                  <v-btn
                    v-if="isVideoInImageStudio"
                    color="secondary"
                    class="white--text ma-2"
                    small
                    fab
                    absolute
                  >
                    <v-icon large>
                      check
                    </v-icon>
                  </v-btn>

                  <video
                    :width="600"
                    height="auto%"
                    controls
                    disablePictureInPicture
                    controlsList="nodownload"
                    :src="videoUrl"
                    crossorigin="use-credentials"
                  >
                    {{ $t('capturePage.videoNotSupport') }}
                  </video>
                </div>
              </v-col>
              <v-col />
            </v-row>
            <v-row v-else-if="isAudio"
                   justify="center"
            >
              <v-col>
                <v-row>
                  <v-col />
                  <v-col md="auto">
                    <span class="text-h6 mx-3">
                      {{ $t('capturePage.audioPlayer') }}
                    </span>
                  </v-col>
                  <v-col />
                </v-row>
                <v-row>
                  <v-col />
                  <v-col md="auto">
                    <audio
                      width="100%"
                      height="auto%"
                      justify="center"
                      controls
                      controlsList="nodownload"
                      crossorigin="use-credentials"
                    >
                      <source :src="audioUrl"
                              type="audio/wav"
                      >
                      {{ $t('capturePage.videoNotSupport') }}
                    </audio>
                  </v-col>
                  <v-col />
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else-if="is3DViewer"
                   justify="center"
            >
              <ModelPly
                ref="plyModel"
                :height="600"
                :width="800"
                :src="captureModelUrl"
                :texture-url="captureModelTexture"
                class="canvas"
                @on-load="onModelLoad"
              />
            </v-row>
          </v-card-text>
          <CaptureDetailsSelect
            v-if="!is3DViewer"
            :view="view"
            :count-selected="countSelected"
            :all-selected="selectedImages.length === images.length"
            :video-selected="isVideoInImageStudio"
            :preset-profiles="presets"
            margin="16"
            @clear="clearSelectedImages"
            @add="addToImageStudio"
            @add-video="addVideo"
            @select-by-filters="selectByFilters"
            @remove-video="removeVideo"
            @download-video="downloadVideo"
            @scan-selected-frames="scanSelectedFrames(capture)"
          />
          <CaptureDetails3DSelect
            v-else-if="is3DViewer"
            v-model="renderMode"
            :is-avatar="isAvatar"
            @export="export3D"
            @render-mode-changed="renderModeChanged"
          />
        </v-card>
      </v-col>
    </v-row>
    <CaptureDetailsInfo :show-dialog="infoDialog"
                        :capture="capture"
                        @closed="infoDialog=false"
                        @refresh="$emit('refresh')"
    />
    <AccountCaptureDelete :dialog="showDelete"
                          :capture="capture"
                          @close="closeDelete(false)"
                          @ok="closeDelete(true)"
    />
    <CaptureReassign :show-dialog="showReassign"
                     :capture="capture"
                     @close="closeReassign(false)"
                     @ok="closeReassign(true)"
                     @error="reassignError()"
    />
    <v-snackbar
      v-model="reassignSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.reassignSuccess') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="createAvatarSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.createAvatarSuccess') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="localExportSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.localExportCapture') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="cloudExportSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.cloudExportCapture') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="exportErrorSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.exportInProgress') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="addErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.reassignError') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="existsInImageStudioErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('capturePage.existsInImageStudio') }}
      </div>
    </v-snackbar>
  </v-container>
</template>
<script>
import _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';
import ModelPly from './ModelPLY.vue';
import config from '../../js/config';
import AccountCaptureDelete from '../Account/AccountCaptureDelete.vue';
import CaptureDetailsView from './CaptureDetailsView.vue';
import CaptureDetailsItems from './CaptureDetailsItems.vue';
import CaptureDetailsInfo from './CaptureDetailsInfo.vue';
import CaptureDetailsSelect from './CaptureDetailsSelect.vue';
import CaptureDetails3DSelect from './CaptureDetails3DSelect.vue';
import CaptureReassign from './CaptureReassign.vue';
import capturesService from '../../js/services/capturesService';
import captureExportsService from '../../js/services/captureExportsService';
import avatarsService from '../../js/services/avatarsService';
import videoStudioService from '../../js/services/videoStudioService';
import tagsService from '../../js/services/tagsService';
import userService from '../../js/services/userService';
import imageStudioService from '../../js/services/imageStudioService';
import dermaidService from '../../js/services/dermaidService';
import { ACCOUNTS_PAGE, DERMAID_STUDIO_PAGE } from '../../js/router/pages';
import presetFrameProfilesService from '../../js/services/presetFrameProfilesService';
import applicationService from '../../js/services/applicationService';

export default {
  name:       'CaptureDetails',
  components: {
    AccountCaptureDelete,
    CaptureDetailsView,
    CaptureDetailsItems,
    CaptureDetailsInfo,
    CaptureDetailsSelect,
    CaptureDetails3DSelect,
    CaptureReassign,
    ModelPly,
  },
  props: {
    capture: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      presets:             [],
      activeAdvanced:      false,
      images:              [],
      selectedImages:      [],
      withVideo:           false,
      loadingTags:         false,
      searchTagsText:      '',
      availableTags:       [],
      captureTagsModel:    '',
      captureTags:         null,
      captureModelUrl:     '',
      captureModelTexture: null,
      videoUrl:            '',
      audioUrl:            '',
      threeDUrl:           '',
      rotation:            {
        x: -Math.PI / 2,
        y: 0,
        z: 0,
      },
      view:                             null,
      renderMode:                       capturesService.renderModes.grey,
      modelLoading:                     true,
      infoDialog:                       false,
      showDelete:                       false,
      showReassign:                     false,
      reassignSuccessSnackbar:          false,
      createAvatarSnackbar:             false,
      localExportSnackbar:              false,
      cloudExportSnackbar:              false,
      exportErrorSnackbar:              false,
      addErrorSnackbar:                 false,
      existsInImageStudioErrorSnackbar: false,
      avatarTimer:                      null,
      exportTimer:                      null,
      captureExportId:                  0,
      isCloudEnv:                       applicationService.isInCloudMode(),
    };
  },
  computed: {
    /**
     * Returns count of selected objects.
     *
     * @return {number}
     */
    countSelected() {
      return this.selectedImages.length + (this.withVideo ? 1 : 0);
    },
    /**
     * Check if video is in image studio.
     *
     * @return {boolean}
     */
    isVideoInImageStudio() {
      return videoStudioService.getVideoStudioVideos()
        .map(el => el.video).includes(this.videoUrl);
    },

    /**
     * Returns true if tab is video.
     *
     * @return {boolean}
     */
    isVideo() {
      return this.view === capturesService.views.video;
    },

    /**
     * Returns true if tab is audio.
     *
     * @return {boolean}
     */
    isAudio() {
      return this.view === capturesService.views.audio;
    },

    /**
     * Returns true if tab is video.
     *
     * @return {boolean}
     */
    is3DViewer() {
      if ((this.capture.compression_type === 2 && this.view === capturesService.views.viewer3d)
        || this.view === capturesService.views.avatar) {
        return true;
      }

      return false;
    },
    isAvatar() {
      return this.view === capturesService.views.avatar;
    },

  },
  watch: {
    capture: {
      deep: true,
      handler() {
        this.loadMediaUrls();
      },
    },
  },
  destroyed() {
    clearInterval(this.avatarTimer);
    clearInterval(this.exportTimer);
  },
  mounted() {
    this.loadCaptureImages();
    this.loadPresets();
    this.loadTags();
    this.loadMediaUrls();
  },
  methods: {
    /**
     * Returns true if the user is admin
     *
     * @return {boolean}
     */
    isAdminUser() {
      return userService.isAdmin();
    },

    getTitle() {
      return this.dateCreated();
    },

    changeView(newView) {
      this.view = newView;
    },

    dateCreated() {
      return this.$moment(this.capture.date_created).format(config.dateTimeFormat);
    },

    /**
     * Select images based on filter. All images or every "N" in the range "from" => "to" if it's set.
     *
     * @param {Object} filters - Filters for select images.
     */
    selectByFilters(filters) {
      this.selectedImages = [];
      if (filters.all) {
        this.images.forEach(image => {
          this.selectedImages.push(this.getImageObject(image));
        });

        return;
      }

      if (filters.to || filters.from || filters.every) {
        let { images } = this;

        if (filters.to) {
          images = images.filter(el => el.frame <= filters.to - 1);
        }

        if (filters.from) {
          images = images.filter(el => el.frame >= filters.from - 1);
        }

        if (filters.every) {
          images = images.filter(el => el.frame % filters.every === 0);
        }

        images.forEach(image => {
          this.selectedImages.push(this.getImageObject(image));
        });
      }

      if (filters.presets) {
        this.images
          .filter(el => filters.presets.includes(el.frame + 1))
          .forEach(image => {
            this.selectedImages.push(this.getImageObject(image));
          });
      }

      this.selectedImages = _.uniqWith(this.selectedImages, _.isEqual);
    },

    /**
     * Load tag info.
     */
    async loadMediaUrls() {
      if (this.capture.compression_type === 2) {
        this.captureModelUrl = await capturesService.get3dSrc(this.capture.id);
      } else if (this.capture.has_avatar) {
        // console.log('Getting model and texure');
        this.captureModelUrl = await avatarsService.getModel(this.capture.id);
        this.captureModelTexture = await avatarsService.getTexture(this.capture.id);
        this.videoUrl = await capturesService.getVideoSrc(this.capture.id);
      } else {
        this.videoUrl = await capturesService.getVideoSrc(this.capture.id);
      }

      if (this.capture.audio_file) {
        this.audioUrl = await capturesService.getAudioSrc(this.capture.id);
        // console.log(`${this.audioUrl}`);
      }
    },

    /**
     * Load tag info.
     */
    async loadTags() {
      this.captureTags = await tagsService.loadTags();

      try {
        this.availableTags = this.captureTags.filter(t => !this.captureTags.includes(t.name))
          .map(t => t.name);
      } catch (error) {
        // console.error('Failed to delete capture tag association');
        // console.error(error);
      }
    },

    /**
     * Load capture images.
     */
    async loadCaptureImages() {
      if (this.capture.compression_type === 2) {
        return;
      }
      this.images = await capturesService.getImageList(this.capture.id);
      this.images.forEach(img => {
        const camSettings = this.capture.camera_settings
          .find(o => o.id === img.cameraCaptureSettingsId);

        img.height = camSettings.height;
        img.width = camSettings.width;
      });
    },

    /**
     * Load presets settings.
     */
    async loadPresets() {
      this.presets = await presetFrameProfilesService.getPresetFrameProfiles();
    },

    /**
     * Returns image object in format for image studio.
     *
     * @params {Object} - Image object.
     */
    getImageObject(image) {
      return {
        ...image,
        captureId:   parseInt(this.capture.id, 10),
        accountId:   this.capture.account_id,
        accountName: this.capture.account_name,
      };
    },

    /**
     * Returns video object in format for image studio.
     *
     * @return {Object}
     */
    async getVideoObject() {
      return {
        video:        await capturesService.getVideoSrc(this.capture.id),
        account_name: this.capture.account_name,
        scan_mode:    this.capture.scan_mode,
        date_created: this.capture.date_created,
        captureId:    this.capture.id,
        accountId:    this.capture.account_id,
      };
    },

    /**
     * Returns 3D object in format for image studio.
     *
     * @return {Object}
     */
    get3DObject() {
      return {
        video:     capturesService.get3dSrc(this.capture.id),
        captureId: this.capture.id,
        accountId: this.capture.account_id,
      };
    },

    /**
     * Add or remove image from selected array.
     */
    toggleImage(image) {
      const index = this.selectedImages
        .map(el => el.image)
        .indexOf(image.image);

      if (index >= 0) {
        this.selectedImages.splice(index, 1);
      } else {
        this.selectedImages.push(this.getImageObject(image));
      }
    },

    async exportCapture() {
      if (this.capture.compression_type === 2) {
        await this.exportModel();
      } else {
        await this.exportMedia();
      }
    },

    async createAvatar() {
      await avatarsService.createAvatar(this.capture.id);
      this.avatarTimer = setInterval(this.getAvatarProgress, 5000);
      this.createAvatarSnackbar = true;
    },

    async getAvatarProgress() {
      try {
        // console.log('getAvatarProgress');

        const isComplete = await avatarsService.isAvatarComplete(this.capture.id);

        if (isComplete) {
          // console.log('Progress done');
          clearInterval(this.avatarTimer);
          this.avatarTimer = null;
          this.$emit('refresh');
        }
      } catch {
        clearInterval(this.avatarTimer);
        this.avatarTimer = null;
      }
    },

    async export3D() {
      if (this.view === capturesService.views.avatar) {
        await this.exportAvatar();
      } else {
        await this.exportModel();
      }
    },

    /**
     * Download 3d avatar
     */
    async exportAvatar() {
      try {
        const model = await avatarsService.download(this.capture.id);

        const filename = `${this.$moment().format('MM-DD-YYYY')}_${this.capture.id}_mesh.zip`;

        saveAs(new Blob([model], { type: 'application/zip' }), filename);
        // this.loading = false;
      } catch (error) {
        // console.log(`Error ${error}`);
        // this.loading = false;
        // this.showError(error);
      }
    },

    /**
     * Download 3d model
     */
    async exportModel() {
      try {
        const url = await capturesService.get3dSrc(this.capture.id);

        const model = await capturesService.exportMedia(url);

        const filename = `${this.$moment().format('MM-DD-YYYY')}_${this.capture.id}_mesh.ply`;

        saveAs(new Blob([model], { type: 'model/mesh' }), filename);

        // this.loading = false;
      } catch (error) {
        // console.log(`Error ${error}`);
        // this.loading = false;
        // this.showError(error);
      }
    },

    /**
    * Download all media for capture
    */
    async exportMedia() {
      try {
        if (this.captureExportId > 0) {
          this.exportErrorSnackbar = true;

          return;
        }

        this.captureExportId = await captureExportsService.createCaptureExport(this.capture.id);

        if (this.isCloudEnv) {
          this.cloudExportSnackbar = true;
          this.captureExportId = 0;
        } else {
          this.exportTimer = setInterval(this.getExportProgress, 5000);

          this.localExportSnackbar = true;
        }
      } catch (error) {
        this.captureExportId = 0;
      }
    },

    async getExportProgress() {
      try {
        const isComplete = await captureExportsService
          .isCaptureExportComplete(this.captureExportId);

        if (isComplete) {
          clearInterval(this.exportTimer);
          this.exportTimer = null;
          await this.downloadExportFile();
          this.captureExportId = 0;
        }
      } catch (error) {
        clearInterval(this.exportTimer);
        this.exportTimer = null;
        this.captureExportId = 0;
      }
    },

    async downloadExportFile() {
      try {
        const zipFile = await captureExportsService
          .download(this.captureExportId);
        const filename = `${this.$moment().format('MM-DD-YYYY')}_${this.capture.id}_${this.captureExportId}_mesh.zip`;

        saveAs(new Blob([zipFile], { type: 'application/zip' }), filename);
        // this.loading = false;
      } catch (error) {
        // console.log(`downloadExportFile Error ${error}`);
        // this.loading = false;
        // this.showError(error);
      }
    },

    /**
     * 3D model render type changed
     */
    renderModeChanged(renderMode) {
      if (this.isAvatar) {
        this.$refs.plyModel.setWireframe(false);
        // this.$refs.plyModel.setColor(true);
      } else if (renderMode === capturesService.renderModes.grey) {
        this.$refs.plyModel.setWireframe(false);
        this.$refs.plyModel.setColor(false);
      } else if (renderMode === capturesService.renderModes.wireframe) {
        this.$refs.plyModel.setWireframe(true);
        this.$refs.plyModel.setColor(false);
      } else {
        this.$refs.plyModel.setWireframe(false);
        this.$refs.plyModel.setColor(true);
      }
    },

    /**
     * Clear selected images.
     */
    clearSelectedImages() {
      this.$nextTick(() => {
        this.selectedImages = [];
      });
      // this.removeVideo();
    },

    /**
     * Add selected images to image studio.
     *
     * @param {boolean} withVideo - True if need add also video to Image Studio.
     */
    async addToImageStudio() {
      this.existsInImageStudioErrorSnackbar = !imageStudioService
        .addToImageStudio(this.selectedImages);

      this.$nextTick(() => {
        this.selectedImages = [];
      });
    },

    /**
     * Add selected images to dermaid studio.
     *
     * @param {boolean} withVideo - True if need add also video to Dermaid Studio.
     */
    async addToDermaidStudio() {
      dermaidService.addToDermaidStudio(this.selectedImages);
      this.$nextTick(() => {
        this.selectedImages = [];
      });
    },

    /**
     * Send selected frames to get scanned.
     *
     * @param capture
     */
    async scanSelectedFrames(capture) {
      try {
        const selectedFrames = this.selectedImages.map(img => img.frame);

        const projectData = {
          name:      `${capture.account_name} - ${capture.id}`,
          captureId: capture.id,
          selectedFrames,
        };

        const { id: projectId } = await dermaidService.createDermaidProject(projectData);

        this.$router.push({ name: DERMAID_STUDIO_PAGE, query: { projectId } });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error scanning selected frames:', error);
      }
    },

    /**
     * Add capture video to image studio.
     */
    async addVideo() {
      videoStudioService.addVideo(await this.getVideoObject());
    },

    /**
     * Remove capture video from image studio.
     */
    async removeVideo() {
      const video = await this.getVideoObject();

      videoStudioService.removeVideo(video.captureId);
    },

    async downloadVideo() {
      const video = await capturesService
        .downloadVideo(this.capture.id);

      saveAs(new Blob([video],
        { type: 'video/mp4' }),
      `${this.capture.id}.mp4`);
    },

    /**
     * Remove tag from capture
     */
    removeTag(tag) {
      try {
        const data = {
          capture_id: this.capture.id,
          tag_id:     tag.id,
        };

        tagsService.deleteCaptureTagAssociation(data);

        const tagNames = this.capture.tag_names.filter(t => t.id !== tag.id);

        this.$emit('tags-updated', tagNames);
      } catch (error) {
        // console.error(`deleteCaptureTagAssociation ${error}`);
      }
    },

    /**
     * Search for tags and if it doesn't exist create it.
     */
    async searchTags(tagName) {
      if (tagName) {
        let foundTag = this.captureTags.find(t => t.name === tagName);

        if (!foundTag) {
          const newTag = {
            name:       tagName,
            is_preset:  false,
            is_enabled: false,
          };

          try {
            const tag = await tagsService.createTag(newTag);

            if (!tag) {
              // console.error(`Failed to create new tag ${JSON.stringify(tag)}`);

              return;
            }
            newTag.id = tag.id;
            foundTag = newTag;
          } catch (error) {
            // console.error('Failed to create new tag');
            // console.error(error);

            return;
          }
        }

        const ctData = {
          tag_id:     foundTag.id,
          capture_id: this.capture.id,
        };

        try {
          const response = await tagsService.createCaptureTagAssociation(ctData);

          if (response.id) {
            const tagNames = this.capture.tag_names;

            tagNames.push(foundTag);
            this.$emit('tags-updated', tagNames);
          }
        } catch (error) {
          // console.error('Failed to delete capture tag association');
          // console.error(error);
        }
        this.searchTagsText = '';
        this.captureTagsModel = null;
      }
    },

    onModelLoad() {
      // this.rotate();
      this.renderModeChanged(this.renderMode);
      this.modelLoading = false;
    },

    rotate() {
      this.rotation.x += 0.01;
      requestAnimationFrame(this.rotate);
    },

    closeDelete(navigate) {
      this.showDelete = false;
      if (navigate) {
        this.$router.push({
          name: ACCOUNTS_PAGE,
        });
      }
    },
    closeReassign(reassign) {
      this.showReassign = false;
      if (reassign) {
        this.reassignSuccessSnackbar = true;
      }
      this.$emit('refresh');
    },
    reassignError() {
      this.showReassign = false;
      this.addErrorSnackbar = true;
    },
  },
};
</script>
<style lang="scss" module>
  @import '../../css/variables';

  .title {
    background-color: $blue-title-2;
    color: #FFFFFF;
  }

  .buttons {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .top {
    padding: 20px 40px 0;
  }

  .detailsWrapper {
    background-color: $grey-3;
    padding: 0 40px;
  }

  .exportWrapper {
    padding: 0 40px;
  }

  .advancedItemLabel {
    color: $gray-text;
  }
  .advancedItem {
    font-weight: bold;
  }

</style>
