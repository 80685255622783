<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <!--<h1 class="subHeader">
        {{ $t('videoStudio.title') }}
      </h1>-->
      <v-breadcrumbs large
                     :items="breadcrumbs"
      >
        <template #item="{ item }">
          <v-breadcrumbs-item :to="item.to"
                              :disabled="item.disabled"
          >
            <h1 v-if="item.disabled"
                class="subHeader"
            >
              {{ item.text }}
            </h1>
            <h1 v-else
                class="breadcrumbLink"
            >
              {{ item.text }}
            </h1>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row id="menu-bar"
           class="text-center d-flex"
    >
      <v-col class="justify-space-between" />
      <!--<v-spacer />-->
      <v-col class="justify-space-between">
        <v-btn-toggle v-model="view"
                      color="secondary"
                      group
                      mandatory
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="orange--text text--darken-3"
                     large
                     :value="views.comparison"
                     v-on="on"
              >
                <v-icon dark>
                  video_label
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('videoStudio.compareTooltip') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="orange--text text--darken-3"
                     large
                     :value="views.playlistComparison"
                     v-on="on"
              >
                <v-icon dark>
                  playlist_play
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('videoStudio.playlistTooltip') }}
            </span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
      <v-col class="justify-space-between">
        <div class="d-flex justify-end">
          <v-speed-dial v-model="showOverlayMenu"
                        direction="bottom"
                        transition="slide-y-transition"
                        class="mr-3"
          >
            <template #activator>
              <v-btn icon>
                <v-icon v-if="!showOverlayMenu">
                  info
                </v-icon>
                <v-icon v-else>
                  cancel
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group v-model="selectedOverlayOptions"
                                 multiple
              >
                <v-list-item color="primary"
                             disabled
                >
                  {{ $t('videoStudio.overlayOptionsMenuTitle') }}
                </v-list-item>
                <template v-for="option in overlayOptions">
                  <v-list-item :key="option.key"
                               :value="option.key"
                               active-class="secondary--text text--accent-4"
                  >
                    <template #default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>{{ option.name }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox :input-value="active"
                                    color="secondary accent-4"
                        />
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-speed-dial>
          <v-btn ref="mx-2"
                 class="secondary"
                 @click="exportDialog=true"
          >
            {{ $t('videoStudio.save') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card :loading="exportLoading">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudio.exportDialog.title') }}
        </v-card-title>
        <v-card-text>
          <input v-model="fileName"
                 style="padding: 10px;
                  border: 1px solid;
                  margin-top: 12px;
                  width: 100%;"
                 type="text"
          >
          <div v-if="showOrientationOptions"
               style="margin-top: 12px;"
          >
            <p>Select Export Orientation:</p>
            <v-radio-group v-model="exportDirection">
              <v-radio label="Landscape"
                       value="landscape"
              />
              <v-radio label="Portrait"
                       value="portrait"
              />
            </v-radio-group>
          </div>
          <v-alert v-if="exportError"
                   type="error"
          >
            {{ $t('imageStudio.exportDialog.error') }}
          </v-alert>
          <v-btn ref="saveBtn"
                 class="saveBtn ma-2"
                 @click="save"
          >
            Export
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="pa-0">
        <VideoCompareSideBySide v-if="view === views.comparison"
                                :ref="views.comparison"
                                max-width="1100"
                                :videos="videos"
                                :show-date="dateSelected"
                                :show-timestamp="timestampSelected"
                                :export-direction="exportDirection"
                                @videos-update="videosUpdate"
                                @save-error="saveError"
                                @save-completed="saveCompleted"
        />
        <VideoComparePlaylist v-else
                              :ref="views.playlistComparison"
                              :videos="videos"
                              :show-date="dateSelected"
                              :show-timestamp="timestampSelected"
                              @playing-updated="updatePlayingVideos"
                              @videos-update="videosUpdate"
                              @save-error="saveError"
                              @save-completed="saveCompleted"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="saveFinished"
                :timeout="snackBarTimeout"
                color="primary"
    >
      <div class="font-weight-bold">
        {{ $t('videoStudio.saveFinished') }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="saveStarted"
                :timeout="snackBarTimeout"
                color="primary"
    >
      <div class="font-weight-bold">
        {{ $t('videoStudio.saveStarted') }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="saveLocalError"
                :timeout="snackBarTimeout"
                color="error"
    >
      <div class="font-weight-bold">
        {{ $t('videoStudio.saveError') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import videoStudioService from '../js/services/videoStudioService';
import imageStudioService from '../js/services/imageStudioService';
import VideoCompareSideBySide from '../components/imageStudio/VideoCompareSideBySide.vue';
import VideoComparePlaylist from '../components/imageStudio/VideoComparePlaylist.vue';
import capturesService from '../js/services/capturesService';
import { ACCOUNT_PAGE, CAPTURE_PAGE } from '../js/router/pages';
import applicationService from '../js/services/applicationService';

export default {
  name:       'VideoStudio',
  components: {
    VideoCompareSideBySide,
    VideoComparePlaylist,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === CAPTURE_PAGE || from.name === ACCOUNT_PAGE) {
        vm.breadcrumbs.push({
          text:     vm.$t(from.meta.title),
          disabled: false,
          to:       from,
        });
      }
      vm.breadcrumbs.push(vm.videoStudioBreadcrumb);
    });
  },
  data() {
    return {
      view:                   videoStudioService.getView(),
      views:                  videoStudioService.views,
      showOrientationOptions: false,
      videos:                 [],
      snackBarTimeout:        5000,
      saveFinished:           false,
      saveStarted:            false,
      saveLocalError:         false,
      prevRoute:              null,
      breadcrumbs:            [],
      showOverlayMenu:        false,
      fileName:               '',
      exportDialog:           false,
      exportLoading:          false,
      exportError:            false,
      exportDirection:        'landscape',
      overlayOptions:         [ {
        name: this.$t('videoStudio.overlayOptionsDate'),
        key:  'overlayDate',
      }, {
        name: this.$t('videoStudio.overlayOptionsTimestamp'),
        key:  'overlayTimestamps',
      },
      ],
      selectedOverlayOptions: [],
      videoStudioBreadcrumb:  {
        text:     this.$t('videoStudio.title'),
        disabled: true,
        href:     null,
      },
    };
  },
  computed: {
    dateSelected() {
      if (!this.selectedOverlayOptions) {
        return false;
      }

      return this.selectedOverlayOptions.includes('overlayDate');
    },

    timestampSelected() {
      if (!this.selectedOverlayOptions) {
        return false;
      }

      return this.selectedOverlayOptions.includes('overlayTimestamps');
    },
  },
  watch: {
    view(newVal) {
      videoStudioService.setView(newVal);

      if (newVal === this.views.playlistComparison) {
        this.showOrientationOptions = false;
        this.exportDirection = 'landscape';
      } else if (newVal === this.views.comparison) {
        this.showOrientationOptions = false; // Reset first

        this.$nextTick(async () => {
          this.videos = await videoStudioService.getSideBySideSelectedVideos();

          if (this.videos.length === 2) {
            this.showOrientationOptions = true;
          }

          this.$emit('playing-updated', this.videos);
        });
      }
    },
    async exportDialog() {
      this.exportDirection = 'landscape';

      const captureIds = this.videos.map(vid => vid.captureId);

      if (this.videos && this.videos.length === 2) {
        this.showOrientationOptions = true;
      } else {
        this.showOrientationOptions = false;
        this.exportDirection = 'landscape';
      }

      const response = await imageStudioService
        .getDownloadInfo(
          captureIds,
          '.mp4',
        );

      this.fileName = response.filename;
    },
  },
  async mounted() {
    await this.loadNewVideos();
  },
  methods: {

    // We load all the new video urls when the page is loaded.
    // our subcomponents wont need to worry about it.
    async loadNewVideos() {
      const videos = videoStudioService.getVideoStudioVideos().map(video => ({
        ...video,
        preview: capturesService.getPreview(video.captureId),
      }));

      const promises = [];

      videos.forEach(video => {
        // console.log(`loading ${video.captureId}`);

        promises.push(capturesService.getVideoSrc(video.captureId)
          .then(url => {
            // console.log(`loaded ${video.captureId}`);
            video.video = url;
          }));
      });

      await Promise.all(promises);

      this.videos = videos;
    },
    updatePlayingVideos(selectedVideos) {
      if (this.view === this.views.comparison) {
        this.showOrientationOptions = false; // Reset first
        setTimeout(() => {
          this.showOrientationOptions = selectedVideos.length === 2;
        }, 100);
      } else {
        this.showOrientationOptions = false;
      }
    },

    videosUpdate(videos) {
      this.videos = videos;

      // Make sure the playlist videos do NOT trigger portrait mode
      if (this.view === this.views.comparison) {
        this.showOrientationOptions = this.selectedVideos.length === 2;
      } else {
        this.showOrientationOptions = false;
      }
    },
    close() {
      this.infoDialog = false;
      this.exportDialog = false;
    },
    isValidFileName(filename) {
    // filename should not be empty
      if (!filename.trim()) {
        this.exportError = 'Filename cannot be empty';

        return false;
      }

      // filename should not be too long
      if (filename.length > 255) {
        this.exportError = 'Filename is too long';

        return false;
      }

      return true;
    },

    async save() {
      await this.$refs[this.view].save(this.fileName, this.exportDirection);

      // Only launch toaster for cloud
      if (applicationService.isInCloudMode()) {
        this.saveFinished = true;
        this.$refs.saveBtn.disabled = false;
      } else {
        this.saveStarted = true;
      }

      this.close();
    },

    saveError() {
      this.saveLocalError = true;
      this.$refs.saveBtn.disabled = false;
    },

    saveCompleted() {
      this.$refs.saveBtn.disabled = false;
    },

  },

};
</script>

<style lang="scss" scoped>
@import "../css/variables.scss";

.subHeader {
  color: $grey-darken-1;
}

</style>
