import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import sha512 from 'sha512';
import i18n from '../lang/i18n';
import store from '../store/store';
import { USER_MUTATION } from '../store/mutations';
import { AUTH_USER_GETTER, DERMAID_ENABLED_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store.
   */
  ...mapMutations([USER_MUTATION]),

  /**
   * Getters from Vuex Storage.
   */
  ...mapGetters([ AUTH_USER_GETTER, DERMAID_ENABLED_GETTER,
  ]),

  /**
   * User's permissions.
   *   6 - is for organization admins.
   *   5 - is the highest it is meant for ovio internal.
   *   4 - is for system technicians.
   *   3 - is for client admins.
   *   1 and 2 - are user levels.
   *   0 - is Everyone.
   */
  permissions: {
    orgAdministrator: {
      accessLevel: 6,
      name:        i18n.t('permissionNames.orgAdministrator'),
    },
    oVio: {
      accessLevel: 5,
      name:        i18n.t('permissionNames.oVio'),
    },
    technician: {
      accessLevel: 4,
      name:        i18n.t('permissionNames.technician'),
    },
    administrator: {
      accessLevel: 3,
      name:        i18n.t('permissionNames.administrator'),
    },
    user: {
      accessLevel: 2,
      name:        i18n.t('permissionNames.user'),
    },
  },

  /**
   * Get user list from api.
   *
   * @return {Promise}
   */
  getUserList() {
    return Vue.axios
      .get('api/users')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Create user.
   *
   * @params {Array} data - Array with user data.
   *
   * @return {Promise}
   */
  async createUser(data) {
    // For the cloud, we create users without a password.
    if (data.password) {
      const response = await Vue.axios.post('/api/users', {
        ...data,
        password: sha512(data.password).toString('hex'),
      });

      return response.data;
    }

    const response = await Vue.axios.post('/api/users', {
      ...data,
    });

    return response.data;
  },

  /**
   * Update user data.
   *
   * @params {Array} data - Array with user data.
   *
   * @return {Promise}
   */
  async updateUser(data) {
    const authUser = this.getAuthUser();

    const updateUserState = response => {
      if (authUser.id === data.id) {
        store.commit(USER_MUTATION, response.data);
      }

      return response.data;
    };

    return Vue.axios
      .put(
        `/api/users/${data.id}`,
        data.password
          ? {
            ...data,
            password: sha512(data.password).toString('hex'),
          }
          : data,
      )
      .then(response => updateUserState(response))
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Returns permission name by level.
   *
   * @param {integer} accessLevel - Permission access level.
   *
   * @return {string}
   */
  getPermissionName(accessLevel) {
    return Object.values(this.permissions).find(
      el => el.accessLevel === accessLevel,
    ).name;
  },

  /**
   * Get image from api and convert it to base64.
   *
   * @param {string} imagePath - URL to the user's picture
   *
   * @return {string}
   */
  getImage(imagePath) {
    return Vue.axios
      .get(imagePath, {
        headers:      { 'Cache-Control': 'no-cache' },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const image = Buffer
          .from(response.data, 'binary')
          .toString('base64');

        return `data:${response.headers[
          'content-type'
        ].toLowerCase()};base64,${image}`;
      })
      .catch(error => {
        // console.log(`getImage ${error}`);

        throw error.response;
      });
  },

  /**
   * Update user image.
   *
   * @param {Array} user - Array with user data.
   */
  updateUserImage(user) {
    this.getImage(`api/users/${user.id}/image`)
      .then(res => {
        this[USER_MUTATION]({ ...user, image: res });
      })
      .catch(() => {});
  },

  /**
   * Update user profile image.
   *
   * @params {Array} params.user - Array with user data.
   * @params {Array} params.imageFile - Array with new user image data.
   *
   * @return {Array}
   */
  async changeUserImage(params) {
    const data = new FormData();

    data.append('UserId', params.user.id);
    data.append('LogoFile', params.imageFile);

    await Vue.axios
      .post('/api/users/image', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => {
        this.updateUserImage(params.user);

        return response.data;
      })
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Update user password.
   *
   * @params {Array} data - Array with old and new user password.
   *
   * @return {Promise}
   */
  async updateUserPassword(data, withAuth) {
    if (withAuth) {
      const response = await Vue.axios.put('/api/users/password', {
        ...data,
        old_password: sha512(data.old_password).toString('hex'),
        new_password: sha512(data.new_password).toString('hex'),
      });

      return response.data;
    }

    const response = await Vue.axios.post('/api/users/password', {
      ...data,
      old_password: sha512(data.old_password).toString('hex'),
      new_password: sha512(data.new_password).toString('hex'),
    });

    return response.data;
  },

  /**
   * Requests sending a password reset email to the user.
   *
   * @param {string} email - The email of the user.
   * @param {string} username - The username of the user.
   *
   * @return {Promise}
   */
  async initUserPasswordReset(email, username) {
    const referer = window.location.origin;

    return Vue.axios.post('/api/users/password/reset', {
      email,
      username,
    }, {
      headers: {
        Referer: referer,
      },
    });
  },

  /**
 * Makes an API call to reset the user's password by admin.
 *
 * @param {Object} data - The data containing user email and username.
 *
 * @return {Promise}
 */
  async adminResetUserPassword(data) {
    return Vue.axios.post('/api/users/password/reset/admin', {
      email:    data.email,
      username: data.username,
    });
  },

  /**
   * Returns authorized user.
   *
   * @return {null|Object}
   */
  getAuthUser() {
    return this[AUTH_USER_GETTER]();
  },

  /**
   * Returns true if user has access_level more than client admin.
   *
   * @return {boolean}
   */
  isAdmin() {
    const user = this[AUTH_USER_GETTER]();

    return (
      user
      && user.access_level
      && [
        this.permissions.administrator.accessLevel,
        this.permissions.orgAdministrator.accessLevel,
        this.permissions.technician.accessLevel,
        this.permissions.oVio.accessLevel,
      ].includes(user.access_level)
    );
  },

  isOrgAdmin() {
    const user = this[AUTH_USER_GETTER]();

    return (
      user
      && user.access_level
      && [
        this.permissions.orgAdministrator.accessLevel,
        this.permissions.oVio.accessLevel,
      ].includes(user.access_level)
    );
  },

  /**
   * Returns true if logged user has technician role or higher.
   *
   * @return {boolean}
   */
  isTechnician() {
    const user = this[AUTH_USER_GETTER]();

    return (
      user
      && user.access_level
      && [
        this.permissions.oVio.accessLevel,
        this.permissions.technician.accessLevel,
      ].includes(user.access_level)
    );
  },

  /**
   * Returns users permissions available for logged user.
   *
   * @return {Array}
   */
  getAvailablePermissions() {
    const authUser = this.getAuthUser();
    const result = Object.values(this.permissions).filter(
      permission => permission.accessLevel < authUser.access_level,
    );

    if (authUser.access_level === this.permissions.oVio.accessLevel) {
      result.unshift(this.permissions.orgAdministrator);
    }

    if (authUser.access_level === this.permissions.orgAdministrator.accessLevel) {
      const allowedPermissions = [
        this.permissions.administrator.accessLevel,
        this.permissions.user.accessLevel,
      ];

      return result.filter(permission => (
        allowedPermissions.includes(permission.accessLevel)
      ));
    }

    return result;
  },

  getDermaidEnabled() {
    return this[DERMAID_ENABLED_GETTER]();
  },
};
