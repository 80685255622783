<template>
  <div :class="$style.root">
    <v-row v-if="isVideo">
      <v-col class="d-flex flex-row">
        <span class="text-h6 mx-3"
              :class="$style.lowerHeader"
        >
          {{ $t('capturePage.select.titleVideoStudio') }}
        </span>
        <v-btn class="secondary d-flex flex-row"
               :disabled="videoSelected"
               @click="$emit('add-video')"
        >
          {{ $t('capturePage.select.actions.addVideo') }}
        </v-btn>
        <v-btn class="d-flex flex-row mx-2"
               :disabled="!videoSelected"
               @click="$emit('remove-video')"
        >
          {{ $t('capturePage.select.actions.clearVideo') }}
        </v-btn>
        <v-spacer />
        <v-btn class="d-flex flex-row mx-2"
               @click="$emit('download-video')"
        >
          {{ $t('capturePage.select.actions.downloadVideo') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex flex-row">
        <span class="text-h6 mx-3"
              :class="$style.lowerHeader"
        >
          {{ $t('capturePage.select.titleImageStudio') }}
        </span>
        <v-badge :content="countSelected"
                 :value="countSelected"
                 color="primary"
                 overlap
        >
          <v-btn class="white--text d-flex flex-row"
                 color="secondary"
                 :disabled="countSelected === 0"
                 @click="$emit('add')"
          >
            {{ $t('capturePage.select.actions.addSelected') }}
          </v-btn>
        </v-badge>
        <v-btn class="mx-2 d-flex flex-row"
               :disabled="countSelected === 0"
               @click="clear"
        >
          {{ $t('capturePage.select.actions.clearSelected') }}
        </v-btn>
        <v-badge v-if="isDermaidEnabled()"
                 :content="countSelected"
                 :value="countSelected"
                 color="primary"
                 overlap
        >
          <v-btn class="mx-2 d-flex flex-row"
                 :disabled="countSelected === 0 || countSelected > 5"
                 @click="$emit('scan-selected-frames')"
          >
            OVIO AI SCAN
          </v-btn>
        </v-badge>
      </v-col>
      <v-spacer />
      <v-col class="d-flex justify-end mr-3">
        <v-menu top
                left
                close-on-click
                offset-y
                offset-x
        >
          <template #activator="{ on }">
            <v-btn v-on="on">
              Select
            </v-btn>
          </template>
          <v-list color="grey lighten-3">
            <v-list-group no-action
                          @click.stop
            >
              <template #activator>
                <v-list-item-title>
                  {{ $t('capturePage.select.options.presets') }}
                </v-list-item-title>
              </template>

              <v-list-item v-for="profile in presetProfiles"
                           :key="profile.id"
                           class="pl-6"
                           @click="presets = profile.preset_frames"
              >
                <v-list-item-title v-text="profile.name" />
              </v-list-item>
            </v-list-group>
            <v-list-item @click="selectAll = true">
              <v-list-item-title>{{ $t('capturePage.select.options.all') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialog = true">
              <v-list-item-title>{{ $t('capturePage.select.options.range') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <CaptureDetailsSelectDialog v-model="dialog"
                                  @cancel="cancelSelectFrames"
                                  @close="closeSelectFrames"
      />
    </v-row>
  </div>
</template>
<script>
import capturesService from '../../js/services/capturesService';
import userService from '../../js/services/userService';
import CaptureDetailsSelectDialog from './CaptureDetailsSelectDialog.vue';

export default {
  name:       'CaptureDetailsSelect',
  components: {
    CaptureDetailsSelectDialog,
  },
  props: {
    view: {
      type:    String,
      default: capturesService.views.slides,
    },
    countSelected: {
      type:    Number,
      default: 0,
    },
    allSelected: {
      type:    Boolean,
      default: false,
    },
    videoSelected: {
      type:    Boolean,
      default: false,
    },
    presetProfiles: {
      type:    Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectAll:   false,
      selectEvery: null,
      selectFrom:  null,
      selectTo:    null,
      withVideo:   false,
      presets:     [],
      dialog:      false,
    };
  },
  computed: {
    /**
     * Return true for video view.
     *
     * @return {boolean}
     */
    isVideo() {
      return this.view === capturesService.views.video;
    },

    /**
     * Returns selected filters for images.
     *
     * @return {{all: boolean, from: (number|null), to: (number|null), every: (number|null)}}
     */
    selectedFilters() {
      return {
        all:     this.selectAll,
        from:    this.selectFrom ? parseInt(this.selectFrom, 10) : null,
        to:      this.selectTo ? parseInt(this.selectTo, 10) : null,
        every:   this.selectEvery ? parseInt(this.selectEvery, 10) : null,
        presets: this.presets,
      };
    },
  },
  watch: {

    /**
     * Watch changing selected filters.
     */
    selectedFilters() {
      this.$emit('select-by-filters', this.selectedFilters);
    },
  },
  methods: {
    /**
     * Clear all filters.
     */
    clear() {
      this.selectAll = false;
      this.selectFrom = null;
      this.selectTo = null;
      this.selectEvery = null;
      this.presets = false;
      this.$emit('clear');
    },

    closeSelectFrames(from, to, every) {
      this.selectFrom = from;
      this.selectTo = to;
      this.selectEvery = every;
      this.dialog = false;
      // console.log(`${from} ${to} ${every}`);
    },
    cancelSelectFrames() {
      this.dialog = false;
    },

    isDermaidEnabled() {
      return userService.getDermaidEnabled();
    },
  },
};
</script>
<style lang="scss" module>
@import '../../css/variables';

.lowerHeader {
  white-space: nowrap;
}

.root {
  background-color: var(--v-greyAlt3-base);
}
</style>
