<template>
  <v-container fluid
               class="forgot-password"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="forgot-password__container mx-auto my-12">
      <v-img class="flex"
             max-height="250"
             src="@/assets/logo_LG.png"
      />
      <v-card-title class="forgot-password__container--title">
        {{ $t('pageTitles.forgotPassword') }}
      </v-card-title>
      <v-form ref="form"
              v-model="validReset"
              @submit.prevent="onResetPassword"
      >
        <v-label>{{ $t('loginPage.username') }}</v-label>
        <v-text-field v-model="username"
                      color="grey darken-1"
                      placeholder="Your Username"
                      required
        />

        <v-label>{{ $t('loginPage.email') }}</v-label>
        <v-text-field v-model="email"
                      :rules="emailRules"
                      color="grey darken-1"
                      placeholder="Your Email"
                      required
        />

        <div class="forgot-password__container--support-email">
          <span>Need help? Email us &nbsp;</span>
          <a href="mailto:support@ovio360.com">support@ovio360.com </a>
        </div>

        <v-card-actions class="forgot-password__container--actions">
          <v-btn
            elevation="2"
            :disabled="!validReset"
            class="secondary white--text"
            @click="onResetPassword"
          >
            {{ $t('loginPage.passwordRequest') }}
          </v-btn>
        </v-card-actions>
        <app-version class="forgot-password__container--app-version" />
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import userService from '../js/services/userService';
import { LOGIN_PAGE } from '../js/router/pages';
import AppVersion from '../components/AppVersion.vue';

export default {
  name:       'ForgotPassword',
  components: { AppVersion },
  data() {
    return {
      redirectTimeout: -1,
      showMessage:     false,
      message:         '',
      colorMessage:    'success',
      validReset:      false,
      username:        '',
      email:           '',
      emailRules:      [
        v => !!v || this.$t('loginPage.emailValidate'),
      ],
    };
  },
  beforeDestroy() {
    if (this.clearTimeout !== -1) {
      clearTimeout(this.redirectTimeout);
    }
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },
    /**
     * Shows the success message notification.
     */
    showSuccessMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'success';
    },
    /**
     * Resets the password.
     */
    async onResetPassword() {
      // eslint-disable-next-line no-console
      console.log('Resetting password ');

      if (!this.validReset) {
        return;
      }

      try {
        const response = await userService.initUserPasswordReset(this.email, this.username);

        if (response.status === 200) {
          this.showSuccessMessage(this.$t('loginPage.passwordResetSuccess'));

          this.redirectTimeout = setTimeout(() => {
            this.$router.push({ name: LOGIN_PAGE });
          }, 3000);
        } else {
          this.showErrorMessage(this.$t('loginPage.errorPassword'));
        }
      } catch (res) {
        const errorMsg = (res && res.data) ? res.data : this.$t('loginPage.errorPassword');

        this.showErrorMessage(errorMsg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--support-email {
      display: flex;
      justify-content: center;
      color: grey;
      font-size: 0.8em;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 360px) {
  .forgot-password {
    &__container {
      &--support-email {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
